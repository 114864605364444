import "./styles.scss"

import React from "react"
import scrollToElement from "scroll-to-element"
import { motion } from "framer-motion"

import Button from "components/Button"
import AnimatedMask from "components/AnimatedMask"

import fadeAnimation from "utils/fadeAnimation"

const Content = ({ pageData }) => {
  return (
    <section className="system-content">
      <div className="container-fluid">
        <div className="row">
          <motion.div className="col-lg-5 col-md-6" {...fadeAnimation}>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData?.acfSystemWet?.description,
              }}
            />
            <Button
              onClick={() => scrollToElement("#buy")}
              className="button--bordered"
            >
              Jestem zainteresowany
            </Button>
          </motion.div>

          <div className="col-md-6 offset-lg-1">
            <motion.div
              className="system-content__thumbnail"
              {...fadeAnimation}
            >
              <AnimatedMask />
              <img
                src={pageData?.acfSystemWet?.contentImage?.localFile?.publicURL}
                alt=""
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
