import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"

import fadeAnimation from "utils/fadeAnimation"

const Type = ({ pageData }) => {
  return (
    <section className="system-resin-type">
      <div className="container-fluid">
        <SectionHeader title={pageData?.acfSystemWet?.tilesHeader} />
        <div className="row">
          {pageData?.acfSystemWet?.tilesContent?.map((item, index) => (
            <motion.div
              className="col-lg-5 offset-lg-1 col-md-6"
              key={index}
              {...fadeAnimation}
            >
              <img src={item?.image?.localFile?.publicURL} alt="" />
              <h3>{item?.header}</h3>
              <p>{item?.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Type
