import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

const Why = ({ pageData }) => {
  return (
    <section className="system-why">
      <div className="container-fluid">
        <motion.h3 {...fadeAnimation}>
          {pageData?.acfSystemWet?.whyHeader}
        </motion.h3>
        <div className="row">
          {pageData?.acfSystemWet?.whyIcons?.map((item, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <motion.div className="system-why__item" {...fadeAnimation}>
                <div className="system-why__item-icon">
                  <img
                    src={item?.icon?.localFile?.publicURL}
                    alt={item?.header}
                  />
                </div>
                <strong>{item?.header}</strong>
                <p>{item?.description}</p>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Why
