import "./styles.scss"

import React, { useEffect, useState } from "react"
import { withPrefix } from "gatsby"
import axios from "axios"
import { motion } from "framer-motion"

import ProductBuy from "components/ProductBuy"

import { useProducts } from "context/products"

import fadeAnimation from "utils/fadeAnimation"

const Buy = ({ setPopup }) => {
  const variation_product = 13189
  const { products } = useProducts()

  const [product_variations, setProductVariations] = useState([])
  const [selected_variant, setSelectedVariant] = useState([])

  useEffect(() => {
    ;(async () => {
      if (!!!products) return
      await getProductVariation()
    })()
  }, [products])

  const getProductVariation = async () => {
    try {
      const variations = await axios?.get(
        `https://cms.wallcraft.com.pl/wp-json/wc/v3/products/${variation_product}/variations?consumer_key=ck_6308078b67bd575fd2cb12f92e564622d4fe3938&consumer_secret=cs_405c5f1c93af27c9954a41bcacc2eb33023684e3`
      )

      setProductVariations([
        ...variations?.data?.map(item => ({
          id: item?.id,
          name: item?.attributes[0].option,
          price: item?.price,
          image: item?.image?.src,
        })),
      ])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    setSelectedVariant(product_variations[0])
  }, [product_variations])

  return products
    ?.filter(item => item.name === "System z żywicą")
    .map((item, index) => (
      <div key={index}>
        <span className="anchor" id="buy" />
        <motion.section className="system-buy" {...fadeAnimation}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="system-buy__image">
                  <img src={selected_variant?.image} alt="" />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="system-buy__inner">
                  <div>
                    <h4>{item?.name}</h4>
                    <div
                      className="system-buy__description"
                      dangerouslySetInnerHTML={{ __html: item?.content }}
                    />
                  </div>
                  <ProductBuy
                    id={item?.id}
                    databaseId={selected_variant?.id}
                    name={item?.name}
                    product_variations={product_variations}
                    setPopup={setPopup}
                    specification={withPrefix("/metroresin-matt-1.pdf")}
                    selected_variant={selected_variant}
                    setSelectedVariant={setSelectedVariant}
                    product={selected_variant}
                    system
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.section>
      </div>
    ))
}

export default Buy
